/* eslint-disable import/no-extraneous-dependencies */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { get } from 'lodash';
import { RootState } from 'redux/store';
import request from 'util/request';

type Status = 'idle' | 'loading' | 'success' | 'error';

export interface IRegion {
  US: string;
  VN: string;
  SG: string;
  EU: string;
  CA: string;
  DE: string;
  AU: string;
  UK: string;
  FR: string;
  NL: string;
  JP: string;
}
export interface PayloadCreateServer {
  plan_id: number;
  duration: number;
  quantity: number;
  auto_renew: boolean;
  os_id: number;
  random_password: boolean;
  password?: string;
  random_remote_port: boolean;
  remote_port?: number;
  install_chrome: boolean;
  install_firefox: boolean;
  note: string;
  coupon: string;
  range_ip?: string;
  provider?: string;
  state?: string;
  isp?: string;
  nation?: string;
  proxy_type?: string;
  is_proxy?: boolean;
}

export interface PayloadActionGetListServer {
  region: string | null;
}

export interface PayloadActionGetListServerSupportOption {
  plan_id: number;
  state?: string;
  provider?: string;
}

export interface PayloadActionListServerDetail {
  id: number;
  slug: string;
  name: string;
  description: string;
  price: number | null;
  cpu: string;
  ram: string;
  ssd: string;
  bandwidth: string;
  ethernet_port: string;
  ipv4: string;
  region: string;
  status: string;
  featured: boolean;
}

interface IListRegion {
  flag: string;
  name: string;
  symbol: string;
}

export interface ISupportOption {
  location: {
    label: string;
    type: string;
    multichoice: string;
    option: Record<string, string | string>;
  };
  software: {
    type: string;
    multichoice: string;
    option: {
      chrome: string;
      firefox: string;
    };
  };
  os: {
    type: string;
    multichoice: string;
    option: Record<string, number | boolean | string>;
  };
  password: {
    type: string;
    multichoice: string;
    option: {
      random: string;
    };
  };
  random_password: {
    type: string;
    multichoice: string;
    option: {
      password: string;
    };
  };
  port: {
    type: string;
    multichoice: string;
    option: {
      port: string;
    };
  };
  provider: {
    type: string;
    multichoice: string;
    option: Record<string, string | string>;
  };
  ip: {
    type: string;
    multichoice: string;
    option: Record<string, number | boolean | string>;
  };
  duration: {
    type: string;
    multichoice: string;
    option: Record<string, number | boolean | string>;
  };
}

interface ICreateServer {
  amount_before: string;
  amount_last: string;
  id: number;
  ip: string;
  password: string;
  remote_port: string;
  total_price: string;
  username: string;
}

interface ICreateServers {
  servers: ICreateServer[];
  errors: Record<number, string>;
}

interface IBuyServer {
  statusLoadListRegion: Status;
  statusLoadListSupportOption: Status;
  statusLoadingListServer: Status;
  statusLoadingCreateServer: Status;
  listRegion: IListRegion[];
  listSupportOption: ISupportOption;
  paramListServer: PayloadActionGetListServer;
  paramsCreateServer: PayloadCreateServer;
  createdServers: ICreateServers;
  createServerErrorMessage: string | undefined;
  listServerDetail: PayloadActionListServerDetail[];
}

const initialState: IBuyServer = {
  createdServers: {
    servers: [],
    errors: {},
  },
  createServerErrorMessage: '',
  paramsCreateServer: {
    plan_id: 1,
    duration: 1,
    quantity: 1,
    auto_renew: false,
    os_id: 1,
    random_password: true,
    password: '',
    random_remote_port: true,
    remote_port: 0,
    install_chrome: false,
    install_firefox: false,
    note: '',
    coupon: '',
    range_ip: '',
  },
  listRegion: [],
  listSupportOption: {
    location: {
      label: '',
      type: '',
      multichoice: '',
      option: {},
    },
    software: {
      type: 'checkbox',
      multichoice: 'true',
      option: {
        chrome: 'chrome',
        firefox: 'firefox',
      },
    },
    os: {
      type: 'dropdown',
      multichoice: 'false',
      option: {},
    },
    password: {
      type: 'checkbox',
      multichoice: 'false',
      option: {
        random: 'Random password',
      },
    },
    random_password: {
      type: 'checkbox',
      multichoice: 'false',
      option: {
        password: 't4k*i8mQC#',
      },
    },
    port: {
      type: 'checkbox',
      multichoice: 'false',
      option: {
        port: 'Random port',
      },
    },
    provider: {
      type: 'dropdown',
      multichoice: 'false',
      option: {},
    },
    ip: {
      type: 'dropdown',
      multichoice: 'false',
      option: {},
    },
    duration: {
      type: 'dropdown',
      multichoice: 'false',
      option: {},
    },
  },
  paramListServer: {
    region: '',
  },
  listServerDetail: [],
  statusLoadListRegion: 'idle',
  statusLoadListSupportOption: 'idle',
  statusLoadingCreateServer: 'idle',
  statusLoadingListServer: 'idle',
};

export const actionGetListRegion = createAsyncThunk(
  'buyServer/actionGetListRegion',
  async (params, { rejectWithValue }) => {
    try {
      const response = await request({
        url: 'api/plan/region',
        method: 'GET',
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionGetListServer = createAsyncThunk(
  'buyServer/actionGetListServer',
  async (params: PayloadActionGetListServer, { rejectWithValue }) => {
    try {
      const response = await request({
        url: 'api/plan/vps',
        method: 'GET',
        params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionGetListServerSupportOption = createAsyncThunk(
  'buyServer/actionGetListServerSupportOption',
  async (params: PayloadActionGetListServerSupportOption, { rejectWithValue }) => {
    try {
      const response = await request({
        url: 'api/server/vps/support',
        method: 'GET',
        params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionCreateServer = createAsyncThunk(
  'buyServer/actionCreateServer',
  async (params: PayloadCreateServer, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/api/server/create',
        method: 'POST',
        data: params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'buyServer',
  initialState,
  reducers: {
    actionResetServerDetailStore() {
      return initialState;
    },
    actionResetCreateServer(state) {
      state.statusLoadingCreateServer = 'idle';
    },
  },

  extraReducers: (builder) => {
    // get list region
    builder.addCase(actionGetListRegion.pending, (state) => {
      state.statusLoadListRegion = 'loading';
    });
    builder.addCase(actionGetListRegion.fulfilled, (state, action) => {
      state.statusLoadListRegion = 'success';
      state.listRegion = get(action, 'payload.data');
    });
    builder.addCase(actionGetListRegion.rejected, (state) => {
      state.statusLoadListRegion = 'error';
    });

    // get list support option
    builder.addCase(actionGetListServerSupportOption.pending, (state) => {
      state.statusLoadListSupportOption = 'loading';
    });
    builder.addCase(actionGetListServerSupportOption.fulfilled, (state, action) => {
      state.statusLoadListSupportOption = 'success';
      state.listSupportOption = get(action, 'payload.data');
    });
    builder.addCase(actionGetListServerSupportOption.rejected, (state) => {
      state.statusLoadListSupportOption = 'error';
      notification.error({ message: 'Đã có lỗi xảy ra. Vui lòng thử lại!' });
    });

    // get list server detail
    builder.addCase(actionGetListServer.pending, (state) => {
      state.statusLoadingListServer = 'loading';
    });
    builder.addCase(actionGetListServer.fulfilled, (state, action) => {
      state.statusLoadingListServer = 'success';
      state.listServerDetail = get(action, 'payload.data').map((item: PayloadActionListServerDetail) => ({ ...item }));
    });
    builder.addCase(actionGetListServer.rejected, (state) => {
      state.statusLoadingListServer = 'error';
    });

    builder.addCase(actionCreateServer.pending, (state) => {
      state.statusLoadingCreateServer = 'loading';
    });
    builder.addCase(actionCreateServer.fulfilled, (state, action) => {
      state.statusLoadingCreateServer = 'success';
      state.createdServers = get(action, 'payload.data');
    });
    builder.addCase(actionCreateServer.rejected, (state, action) => {
      state.statusLoadingCreateServer = 'error';
      if (get(action, 'payload.response.data.reason')) {
        state.createServerErrorMessage = get(action, 'payload.response.data.reason');
      }
    });
  },
});

export const { actionResetServerDetailStore, actionResetCreateServer } = slice.actions;

export const selectListRegion = (state: RootState) => state.buyServer.listRegion;
export const selectLoadingListRegion = (state: RootState) => state.buyServer.statusLoadListRegion;
export const selectListSupportOption = (state: RootState) => state.buyServer.listSupportOption;
export const selectListServerDetail = (state: RootState) => state.buyServer.listServerDetail;
export const selectCreateServer = (state: RootState) => state.buyServer.createdServers;
export const selectCreateServerErrorMessage = (state: RootState) => state.buyServer.createServerErrorMessage;
export const selectLoadingListSupportOption = (state: RootState) => state.buyServer.statusLoadListSupportOption;
export const selectLoadingListServer = (state: RootState) => state.buyServer.statusLoadingListServer;
export const selectLoadingCreateServer = (state: RootState) => state.buyServer.statusLoadingCreateServer;

export default slice.reducer;
